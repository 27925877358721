import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      // main: "#078DEE",
      // light: "#FEF7F6",
      main: "#06a953",
      contrastText: "#fff",
    },
    secondary: {
      light: "#33eb91",
      main: "#ee262c",
      dark: "#00a152",
      contrastText: "#000",
    },

    common: {
      black: "#292323",
      white: "#efefef",
      grey: "#555555",
    },
    type: "light",
  },
  background: {
    paper: "#424242",
    default: "#303030",
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.5)",
    hint: "rgba(255, 255, 255, 0.5)",
    icon: "rgba(255, 255, 255, 0.5)",
    divider: "rgba(255, 255, 255, 0.12)",
  },
  typography: {
    htmlFontSize: 15,
    fontSize: 12,
    fontWeightMedium: "400",
    fontFamily: "Public Sans, sans-serif",
    h1: {
      fontSize: "44px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "40px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "36px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "32px",
      fontWeight: 700,
    },
    h5: {
      fontSize: "28px",
      fontWeight: 700,
    },
    h6: {
      fontSize: "24px",
      fontWeight: 700,
    },
  },
  spacing: 6,
  //Override the componants
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {},
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
          fontFamily: "Public Sans, sans-serif",
          fontSize: "1rem",
          fontWeight: 700,
          "&:hover": {
            textDecoration: "none",
            boxShadow: "none",
          },
        },
      },
    },
  },
});
