import React from "react";
import { Box, Link, Typography } from "@mui/material";
import CustomContainer from "common/CustomContainer";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import LOGO_ICON from "assets/images/logo.png";
import { AiOutlineHome } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { RiArrowRightSFill } from "react-icons/ri";

const Icon = ({ icon, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        width: "35px",
        height: "35px",
        lineHeight: "37px",
        textAlign: "center",
        color: "#ffffff",
        borderRadius: "50px",
        border: "1px solid #ffffff",
        transition: "0.3s",
        cursor: "pointer",
        "&:hover": {
          color: "black",
          backgroundColor: "white",
        },
      }}
    >
      {icon}
    </Box>
  );
};

const FooterList = ({ icon, title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        my: "15px",
        cursor: "pointer",
        alignItems: "center",
        "&:hover": {
          color: "primary.main",
        },
      }}
    >
      {icon}
      <Typography>{title}</Typography>
    </Box>
  );
};

const FooterPageList = ({ title, url }) => {
  return (
    <Link
      href={url ? url : "/"}
      sx={{
        color: "white",
        textDecoration: "none",
        "&:hover": {
          color: "primary.main",
        },
      }}
    >
      <Box
        sx={{ display: "flex", gap: "5px", my: "15px", alignItems: "center" }}
      >
        <RiArrowRightSFill fontSize={25} />
        <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
          {title}
        </Typography>
      </Box>
    </Link>
  );
};

const Footer = () => {
  return (
    <Box sx={{ bgcolor: "#1B2132" }}>
      <CustomContainer>
        <Box className="footerContainer">
          <Box className="footerAddressSection">
            <Box sx={{ height: "150px", width: "200px", mb: 3 }}>
              <img
                src={LOGO_ICON}
                style={{ width: "100%", height: "100%", marginBottom: "20px" }}
              />
            </Box>
            <Typography>Supplying the success!</Typography>
            <Box sx={{ mt: "20px" }}>
              <FooterList
                icon={<AiOutlineHome fontSize={22} />}
                title="Shop No 18, Garden City Hamidiya 1, Ajman (UAE)"
              />
              <Link
                sx={{
                  color: "white",
                  textDecoration: "none",
                  alignItems: "center",
                }}
                href="tel:+971523737831"
              >
                <FooterList
                  icon={<FiPhoneCall fontSize={20} />}
                  title="+971523737831"
                />
              </Link>
              <Link
                sx={{
                  color: "white",
                  textDecoration: "none",
                  alignItems: "center",
                }}
                href="tel:+97165249344"
              >
                <FooterList
                  icon={<FiPhoneCall fontSize={20} />}
                  title="+97165249344"
                />
              </Link>
              <Link
                sx={{
                  color: "white",
                  textDecoration: "none",
                  alignItems: "center",
                }}
                href="tel:+97165216293"
              >
                <FooterList
                  icon={<FiPhoneCall fontSize={20} />}
                  title="+97165216293"
                />
              </Link>
            </Box>
          </Box>
          {/* <Box className="footerLinksContainer">
            <Box className="footerLinksSection">
              <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                Links
              </Typography>
              <FooterPageList title="About Us" />
              <FooterPageList title="Services" url="services" />
              <FooterPageList title="Gallery" />
              <FooterPageList title="Terms" />
              <FooterPageList title="Privacy Policy" />
            </Box>
            <Box className="footerLinksSection">
              <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                Useful Links
              </Typography>
              <FooterPageList title="Home" />
              <FooterPageList title="Branches" />
              <FooterPageList title="Our Brands" />
              <FooterPageList title="Testimonials" />
              <FooterPageList title="Careers" />
              <FooterPageList title="Contact Us" />
            </Box>
          </Box> */}
          {/* <Box className="footerAddressSection">Facebook</Box> */}
        </Box>
        <Box
          className="footerCopyRightSection"
          sx={{ borderColor: "primary.main" }}
        >
          <Typography sx={{ fontSize: "16px", color: "white" }}>
            Copyright @2023 All Rights Reserved by{" "}
            <Typography
              variant="span"
              sx={{
                borderBottom: "1px solid #ffffff",
                paddingBottom: "5px",
                color: "primary.main",
                borderColor: "primary.dark",
                transition: "0.5s",
                cursor: "pointer",
                "&:hover": {
                  color: "primary.dark",
                },
              }}
            >
              Ajwaa Al Zafran
            </Typography>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* <Icon icon={<FaFacebookF />} /> */}
            <Icon
              icon={<AiOutlineInstagram />}
              onClick={() =>
                window.open(
                  "https://instagram.com/ajwaa_al_zafran?igshid=MzRlODBiNWFlZA=="
                )
              }
            />
            <Icon icon={<FaLinkedinIn />} />
          </Box>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default Footer;
