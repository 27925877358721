import { Box } from "@mui/material";
import React from "react";

const CustomContainer = ({ children }) => {
  return (
    <Box maxWidth={1300} width="90%" mx="auto">
      {children}
    </Box>
  );
};

export default CustomContainer;
