import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Layout from "components/layout";
import { Box, Grid } from "@mui/material";
import Loading from "common/Loading";
import "swiper/css";
const Home = lazy(() => import("./components/home"));
const Services = lazy(() => import("./components/services"));
const AboutUS = lazy(() => import("./components/aboutUs"));
const Career = lazy(() => import("./components/carrer"));
const OurPartner = lazy(() => import("./components/ourPartner"));
const ContactUs = lazy(() => import("./components/contactUs"));

const CustomLoading = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className="flipping"
        sx={{
          "&:before": {
            bgcolor: "primary.main",
          },
        }}
      />
    </Box>
  );
};

function App() {
  return (
    <Suspense fallback={<CustomLoading />}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path={"/"} name="Home" element={<Home />} />
            <Route path={"/services"} name="Home" element={<Services />} />
            <Route path={"/about-us"} name="Home" element={<AboutUS />} />
            <Route path={"/career"} name="Home" element={<Career />} />
            <Route path={"/products"} name="Home" element={<OurPartner />} />
            <Route path={"/contact-us"} name="Home" element={<ContactUs />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
