import React, { useState } from "react";
import { BsTelephone } from "react-icons/bs";
import CustomContainer from "common/CustomContainer";
import {
  Box,
  Divider,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UAE_ICON from "assets/images/uae.jpg";
import INDIA_ICON from "assets/images/india_flag.png";
import WHATSAPP_ICON from "assets/images/whatsapp.png";
import LOGO_ICON from "assets/images/logo.png";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CMenu from "common/menu";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const TopHeaderList = ({ icon, title, style, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        gap: "35px",
        marginLeft: "35px",
        cursor: "pointer",
        ...style,
      }}
    >
      <Divider
        orientation="vertical"
        flexItem
        sx={{ borderColor: "#ecf0f1" }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {icon}
        {title}
      </Box>
    </Box>
  );
};

const MenuLink = ({ title, url }) => {
  return (
    <Link
      href={url ? url : "/"}
      sx={{
        color: "black",
        textDecoration: "none",
        "&:hover": {
          color: "primary.main",
        },
      }}
    >
      {title}
    </Link>
  );
};

const ContactList = ({ icon, title, style }) => {
  return (
    <Box sx={{ display: "flex", gap: 1, ...style }}>
      {icon}
      <Typography sx={{ fontWeight: 500 }}>{title}</Typography>
    </Box>
  );
};

const Header = () => {
  const isMobileView = useMediaQuery("(max-width:800px)");
  const [openMenu, setOpenMenu] = useState(false);
  const [openUaeModal, setOpenUaeModal] = useState(null);
  const [openIndModal, setOpenIndModal] = useState(null);

  const handleMenu = () => {
    document.body.style.overflow = openMenu ? "auto" : "hidden";
    setOpenMenu(!openMenu);
  };

  const handleUaeClick = (event) => {
    setOpenUaeModal(event.currentTarget);
  };
  const handleIndClick = (event) => {
    setOpenIndModal(event.currentTarget);
  };

  return (
    <Box>
      <Box sx={{ padding: "7px 0 10px", borderBottom: "0.5px solid #ecf0f1" }}>
        <CustomContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                src={WHATSAPP_ICON}
                style={{ height: "25px", width: "25px" }}
              />
              <Link
                sx={{
                  color: "black",
                  textDecoration: "none",
                  alignItems: "center",
                  ml: 1
                }}
                href="tel:+971523737831"
              >
                <ContactList
                  // icon={
                  //   <LocalPhoneIcon sx={{ fontSize: 17, mr: 1, ml: 0.5 }} />
                  // }
                  title="+971523737831"
                />
              </Link>
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              <TopHeaderList
                onClick={handleIndClick}
                icon={
                  <img
                    src={INDIA_ICON}
                    style={{ height: "15px", width: "25px" }}
                  />
                }
                title={<Typography fontWeight="bold">IND</Typography>}
                style={{ ml: 0, mr: 2 }}
              />

              <CMenu openMenu={openIndModal} setOpen={setOpenIndModal}>
                <Stack direction="column">
                  <Link
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                    href="mailto:shivshakti_trader@yahoo.com"
                  >
                    <ContactList
                      icon={<EmailIcon />}
                      title="shivshakti_trader@yahoo.com"
                    />
                  </Link>
                  <Divider sx={{ my: 2, borderColor: "rgba(0,0,0,0.1)" }} />
                  <ContactList
                    style={{ mb: 1 }}
                    icon={<PersonIcon />}
                    title="Arvind Mahandhwal (Owner)"
                  />
                  <Link
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                    href="tel:+919694292852"
                  >
                    <ContactList
                      icon={
                        <LocalPhoneIcon sx={{ fontSize: 17, mr: 1, ml: 0.5 }} />
                      }
                      title="+919694292852"
                    />
                  </Link>
                </Stack>
              </CMenu>

              <TopHeaderList
                onClick={handleUaeClick}
                icon={<img src={UAE_ICON} />}
                title={<Typography fontWeight="bold">UAE</Typography>}
                style={{ ml: 0, mr: 2 }}
              />
              <CMenu openMenu={openUaeModal} setOpen={setOpenUaeModal}>
                <Stack direction="column">
                  <Link
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                    href="mailto:ajwaaalzafran@gmail.com"
                  >
                    <ContactList
                      icon={<EmailIcon />}
                      title="ajwaaalzafran@gmail.com"
                    />
                  </Link>
                  <Divider sx={{ my: 2, borderColor: "rgba(0,0,0,0.1)" }} />
                  <ContactList
                    style={{ mb: 1 }}
                    icon={<PersonIcon />}
                    title="Jai Mandhawal (Manager)"
                  />
                  <Link
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                    href="tel:00971523737831"
                  >
                    <ContactList
                      icon={
                        <LocalPhoneIcon sx={{ fontSize: 17, mr: 1, ml: 0.5 }} />
                      }
                      title="00971523737831"
                    />
                  </Link>
                  <Divider sx={{ my: 2, borderColor: "rgba(0,0,0,0.1)" }} />

                  <ContactList
                    style={{ mb: 1 }}
                    icon={<PersonIcon />}
                    title="Sumit Kumar (Sales Manager)"
                  />
                  <Link
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                    href="tel:00971557640225"
                  >
                    <ContactList
                      icon={
                        <LocalPhoneIcon sx={{ fontSize: 17, mr: 1, ml: 0.5 }} />
                      }
                      title="00971557640225"
                    />
                  </Link>
                </Stack>
              </CMenu>

              <TopHeaderList
                onClick={() =>
                  window.open(
                    "https://instagram.com/ajwaa_al_zafran?igshid=MzRlODBiNWFlZA=="
                  )
                }
                style={{ marginLeft: 0, gap: "10px" }}
                icon={<AiFillInstagram fontSize={20} />}
              />

              <TopHeaderList
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100086845069955&mibextid=2JQ9oc"
                  )
                }
                style={{ marginLeft: 0, gap: "10px" }}
                icon={<AiFillFacebook fontSize={20} />}
              />
              {/* <TopHeaderList
                style={{ marginLeft: 0, gap: "10px" }}
                icon={<AiFillInstagram fontSize={20} />}
              /> */}
            </Box>
          </Box>
        </CustomContainer>
      </Box>
      <Box sx={{ position: "relative" }}>
        <CustomContainer>
          <Box className="navbarContainer">
            <Box className="navbarLogo">
              <img
                src={LOGO_ICON}
                style={{
                  height: "100%",
                  width: "120px",
                }}
              />
              <Box sx={{ width: "400px", textTransform: "uppercase", ml: 2 }}>
                <Typography
                  sx={{ color: "#06a953", fontFamily: "Roboto Condensed", fontSize: "30px" }}
                  variant="h6"
                >
                  AJWAA{" "}
                  <Typography
                    sx={{ color: "#ee262c", fontFamily: "Roboto Condensed" }}
                    variant="span"
                  >
                    Al
                  </Typography>
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#06a953",
                      fontFamily: "Roboto Condensed",
                    }}
                    variant="span"
                  >
                    Zafran
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    textAlign: "center",
                    mt: -1,
                    marginLeft: "-70px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  FOODSTUFF TRADING L L C
                </Typography>
                {/* <Box sx={{ display: "flex", mt: -1.7, ml: 2 }}>
                  <Typography
                    sx={{ color: "#ee262c", fontFamily: "Roboto Condensed" }}
                    variant="h6"
                  >
                    Al
                  </Typography>
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#06a953",
                      fontFamily: "Roboto Condensed",
                    }}
                    variant="h6"
                  >
                    Zafran
                  </Typography>
                </Box> */}
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: "24px", fontSize: "16px" }}>
              {isMobileView ? (
                <Box
                  className="menuBtnContainer"
                  sx={{ bgcolor: "primary.main" }}
                  onClick={handleMenu}
                >
                  <Box className={openMenu ? "change" : ""}>
                    <Box className="bar1"></Box>
                    <Box className="bar2"></Box>
                    <Box className="bar3"></Box>
                  </Box>
                </Box>
              ) : (
                <>
                  <MenuLink title="Home" />
                  <MenuLink title="About" url="/about-us" />
                  <MenuLink title="Services" url="/services" />
                  {/* <MenuLink title="Our Partner" url="/our-partner" /> */}
                  <MenuLink title="Career" url="/career" />
                  <MenuLink title="Contact Us" url="/contact-us" />
                </>
              )}
            </Box>
          </Box>
        </CustomContainer>
        <Box
          sx={{
            display: openMenu ? "block" : "none",
            bgcolor: "primary.light",
          }}
          className={"mobileMenuContainer"}
        >
          <Box className="mobileMenus">
            <MenuLink title="Home" />
            <MenuLink title="About" url="/about-us" />
            <MenuLink title="Services" url="/services" />
            {/* <MenuLink title="Our Partner" url="/our-partner" /> */}
            <MenuLink title="Career" url="/career" />
            <MenuLink title="Contact Us" url="/contact-us" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
